import React from "react"
import Layout from '../components/layout'
import '../styles/index.scss'
import SEO from "../components/seo"

import {graphql} from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"



    
class IndexPage extends React.Component {
    render() {
        const { data } = this.props
        const siteTitle = data.site.siteMetadata.title

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO title="Home" />
                
                <div class="body_text">
                    <h1>Hello, I'm Matt.</h1>

                    <h2>
                        I'm a <strong>Software Developer</strong> at <OutboundLink href="https://meowwolf.com/" target="_blank" rel="noopener noreferrer">Meow Wolf</OutboundLink>. I am based out of Albuquerque, NM.
                    </h2>

                    <h4>
                        This site is intended as a living document to evolve over my life on the web, and as
                         a hub for me to share things I'm interested in. I'm a hobbyist(amateur) rock climber,
                        photographer, data journalist, and more - I will likely post related content here.
                    </h4>
                    <h5>
                        Opinions expressed are solely my own and do not express the views or opinions of my employer.
                    </h5>

                    <a href="https://en.wikipedia.org/wiki/Special:Contributions/Matthew_Sumpter" target="_blank" rel="noopener noreferrer">Wikipedia Contributions</a>

        
                </div>
            </Layout>
        )
    }
}

export default IndexPage

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`
